import axios, { AxiosInstance } from 'axios'

const authClient: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_OAUTH_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic c29jY2VyOjAyZDQ3MWUwLWQ0NmEtNGMwNS1iZjk5LTUzMDQ1ZGQ2ZjNlYg=='
        //c29jY2VyOjAyZDQ3MWUwLWQ0NmEtNGMwNS1iZjk5LTUzMDQ1ZGQ2ZjNlYg to change app
    }
})

export default authClient
