import apiClient from '@/utils/api-client'

import {Channel} from "@/model/models";

class ChannelService {
    public getChannelList() {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/channels/user').then((res) => {
                const list: Channel[] = [];
                const channelList = res.data.channelsUserList
                // for (const x in channelList) {
                //     if (channelList[x].active) {
                //         list.push(new Channel(channelList[x].channelId, channelList[x].channelName, channelList[x].appName, channelList[x].caption,
                //             channelList[x].eventFirstMatcher, channelList[x].eventSecondMatcher, channelList[x].eventLeague, channelList[x].edgeUrl,
                //             channelList[x].edgePort, channelList[x].pk, channelList[x].tags, channelList[x].orderNumber, channelList[x].status, channelList[x].active));
                //     }
                // }
                //temp code for testing
                for (const x in channelList) {
                    if (channelList[x].active) {
                        list.push(new Channel(channelList[x].channelId, channelList[x].channelName, channelList[x].appName, channelList[x].caption,
                            channelList[x].eventFirstMatcher, channelList[x].eventSecondMatcher, channelList[x].eventLeague, channelList[x].edgeUrl,
                            channelList[x].edgePort, channelList[x].pk, channelList[x].tags, channelList[x].orderNumber, channelList[x].hlsPrefix,
                            channelList[x].hlsSufix, channelList[x].vidictId, channelList[x].status, channelList[x].active));//vidictId or whole func should be deleted
                    }
                }
                list.sort((n1, n2) => {
                    if (n1.channelName > n2.channelName) {
                        return 1;
                    }
                    if (n1.channelName < n2.channelName) {
                        return -1;
                    }
                    return 0;
                });

                list.sort((n1, n2) => {
                    if (n1.orderNumber > n2.orderNumber) {
                        return 1;
                    }
                    if (n1.orderNumber < n2.orderNumber) {
                        return -1;
                    }
                    return 0;
                });
                localStorage.setItem('channelList', JSON.stringify(list))
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default new ChannelService()
