<template>
  <nav class="navbar">
    <div class="container-fluid">
      <a class="navbar-brand" @click="this.$router.push('/')">
        <img class="img-fluid" src="../assets/logo.svg" alt="">
      </a>
    </div>
  </nav>
  <div class="main-height">
    <section class="change-password">
      <div class="container">
        <div class="inner-container">
          <div class="row">
            <div class="col-12">
              <div class="fw-bold">
                <h1 v-if="success.length" class="fw-bold">Password updated!</h1>
                <h1 v-else class="fw-bold">Set new password</h1>
              </div>
              <p v-if="success.length" class="text-success">{{ success }}</p>
              <div v-if="!success.length">
                <div class="register-form">
                  <div class="input-group mt-2">
                    <input v-model="password" required="" type="password" name="password" autocomplete="off" class="input">
                    <label class="user-label">New Password</label>
                  </div>
                  <div class="input-group mt-2">
                    <input v-model="confirmPassword" required="" type="password" name="password" autocomplete="off" class="input" @keyup.enter="doSubmit">
                    <label class="user-label">Retype New Password</label>
                  </div>
                </div>
                <div v-if="error.length" class="alert-danger mt-3 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                  </svg>
                  {{ error }}
                </div>
                <button class="btn btn-primary w-100 mt-3" @click="doResetPassword">
                  <span v-show="!loading">Save</span>
                  <div v-show="loading" class="button-loading">
                    <div class="spinner-border" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <Footer></Footer>
</template>

<script>
import { defineComponent } from 'vue'
import AuthService from '@/services/auth-service'
import {NewPasswordRequest} from "@/model/models";
import UsersService from "@/services/users-service";
import ChannelService from "@/services/channel-service";
import Footer from "@/components/Footer";

export default defineComponent({
  name: 'ResetPasswordPage',
  components: {
    Footer
  },
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      token: '',
      error: '',
      loading: false,
      success: ''
    }
  },
  methods: {
    doResetPassword() {
      this.loading = true;
      if (this.password == '' || this.confirmPassword == '') {
        this.error = "All fields are required.";
        this.loading = false;
      } else if (this.password.length < 6) {
        this.error = 'Password must have at least 6 characters.';
        this.loading = false;
      } else if (this.password != this.confirmPassword) {
        this.error = "New Password fields do not match. Please re-enter.";
        this.loading = false;
      } else {
        AuthService.resetPassword(new NewPasswordRequest(this.email, this.token, this.confirmPassword)).then(() => {
          this.loading = false;
          this.success = 'You\'re being signed in to your account...'
          setTimeout(() => {
            this.loading = true;
            AuthService.signIn(this.email, this.password).then(() => {
              this.doLoadPlans()
            }).catch(() => {
              this.error = 'Login failed';
              this.loading = false;
            })
          }, 2000);
        }).catch((error) => {
          this.error = error.message
          this.loading = false;
        });
      }
    },
    doLoadPlans() {
      UsersService.getUserStaticData().then(() => {
        UsersService.getUserProfile().then(() => {
          const userProfile = JSON.parse(localStorage.getItem('userProfile'))
          const userPlan = JSON.parse(localStorage.getItem('userPlan'))
          if (userProfile.planId == null || userProfile.planId == '') {
            this.$router.push('/plans')
          } else if (userProfile.planActive && userProfile.planStatus) {
            // there is active, paid plan, check end date
            if (userProfile.planEndDate >= Date.now()) {
              ChannelService.getChannelList().then(() => {
                this.$router.push('/events')
              })
            } else {
              this.$router.push('/plans')
            }
          } else if (userProfile.planActive && !userProfile.planStatus) {
            // plan is chosen but not activated
            if (userPlan.trial) {
              // go to trial activation page
              this.$router.push('/trial-activation')
            } else {
              // go to choosing plan from beginning in case they forgot what have chosen
              localStorage.removeItem('userPlan')
              this.$router.push('/plans')
            }
          } else {
            // payment is in process
            this.$router.push('/payment-process')
          }
        }).catch(() => {
          this.error = 'Login failed';
          this.loading = false;
        })
      }).catch(() => {
        this.error = 'Login failed';
        this.loading = false;
      })
    }
  },
  beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const resetToken = urlParams.get('token');
    const urlEmail = urlParams.get('email');
    if(typeof resetToken === 'string') {
      this.token = resetToken;
    } else {
      return this.$router.push('/');
    }
    if (typeof urlEmail === 'string') {
      this.email = decodeURIComponent(urlEmail);
    }
    this.$emit('receiveChange', 'other-work');
  }
});
</script>
<style>
@import '../assets/style.css';
</style>