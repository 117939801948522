import apiClient from '@/utils/api-client'

class RegionService {

    public updateUserRegion(region: string) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/region/update?region=' + region).then(() => {
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }

    public updateUserRegionNull() {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/region/update').then(() => {
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }

}

export default new RegionService()
