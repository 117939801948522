<template>
  <nav>
    <div class="navbar">
      <div class="container-fluid">
        <a class="navbar-brand">
          <img class="img-fluid" width="200px" src="../assets/logo.svg" alt="" @click="goLeagues">
        </a>
        <div class="d-flex">
          <a class="mt-2 me-1" role="button" @click="doAccount">
            <svg class="account-svg bi bi-person-fill" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
            My Account
          </a>
        </div>
      </div>
    </div>
  </nav>
  <div class="card mx-2 mb-2 league-item-container" v-bind:key="league.leagueId" v-for="league in this.leagues">
  <LeagueItem v-bind:league_item="league"></LeagueItem>
  </div>

  <Footer></Footer>
</template>

<script>
import UsersService from "@/services/users-service";
import AuthService from "@/services/auth-service";
import LeagueItem from "@/components/LeagueItem.vue";
import Footer from "@/components/Footer.vue";
import {defineComponent} from 'vue'
import LeagueService from "@/services/league-service";

export default defineComponent({
  name: "Leagues",
  components: {
    // Header,
    LeagueItem,
    Footer
  },
  data() {
    return {
      interval: null,
      userPlan: null,
      userProfile: null,
      error: null,
      leagues: []
    }
  },
  methods: {

    doAccount() {
      this.$router.push('/account')
    },
    getUserLatest: function () {
      UsersService.getUserLatest().then((newUserLatest) => {
        if (newUserLatest) {
          this.compareUserLatest(newUserLatest)
        }
      })
    },
    goLeagues () {
      this.$router.push('/leagues')
    },
    compareUserLatest: function (newUserLatest) {
      if (localStorage.getItem('userLatest') && localStorage.getItem('userLatest') !== JSON.stringify(newUserLatest)) {
        const oldUserLatest = JSON.parse(localStorage.getItem('userLatest'))
        // compare user latest
        if (oldUserLatest.loginId !== newUserLatest.loginId) {
          AuthService.signOut().then(() => {
            localStorage.setItem('sessionExpired', "true")
            this.$router.push('/signin')
          })
        }
        if (oldUserLatest.userProfile != newUserLatest.userProfile
            || oldUserLatest.userPlans != newUserLatest.userPlans) {
          // update user profile
          UsersService.getUserProfile().then(() => {
            if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
            if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
            if (this.userProfile.planActive == undefined && this.userProfile.planStatus == undefined) {
              // refunded user, show plans
              this.$router.push('/plans')
            }
            if (this.userPlan.trial && (this.userProfile.trialOverallTime <= 0 || this.userProfile.trialTodayTime <= 0)) {
              this.$router.push('/plans')
            }
            if (this.userProfile) this.planEndDate = new Date(this.userProfile.planEndDate).toLocaleDateString()
          })
        }
        if (oldUserLatest.payments != newUserLatest.payments) {
          // update user payments
        }//When new userLatest is implemented should be updated
        // if (oldUserLatest.channels != newUserLatest.channels) {
        //   // update user channels
        //   ChannelService.getChannelList().then(() => {
        //     if (localStorage.getItem('channelList')) this.eventsList = JSON.parse(localStorage.getItem('channelList'))
        //     this.mainEvent = this.eventsList.shift()
        //   })
        // }
      }

      this.error = newUserLatest.errorText
      localStorage.setItem('userLatest', JSON.stringify(newUserLatest))
    },
    setData() {
      if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
      if (localStorage.getItem('leagueList')) this.leagues = JSON.parse(localStorage.getItem('leagueList'))

      if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
      if (this.userProfile.planEndDate < Date.now() || (this.userPlan.trial && (this.userProfile.trialOverallTime <= 0 || this.userProfile.trialTodayTime <= 0))) {
        this.$router.push('/plans')
      }
      if (localStorage.getItem('leagueList')) this.eventsList = JSON.parse(localStorage.getItem('leagueList'))
    },
    loadLeagues() {
      LeagueService.getLeagueList().then(() => {
        if (localStorage.getItem('leagueList')) this.leagues = JSON.parse(localStorage.getItem('leagueList'))
        if (this.leagues && this.leagues.length === 1) {
          localStorage.setItem('leagueId', this.leagues[0].leagueId)
          this.$router.push({ name: 'Events'});
        }
      })
    }
  },
  beforeMount() {
    // prevent direct navigation bugs
    if (localStorage.getItem('userPlan') == null
        || localStorage.getItem('userProfile') == null
        || localStorage.getItem('leagueList') == null) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    } else{
      this.loadLeagues()
    }
    this.setData()
  },
  mounted() {
    this.getUserLatest();
    this.interval = setInterval(function () {
      this.getUserLatest();
    }.bind(this), 30000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
})
</script>

<style scoped>

</style>