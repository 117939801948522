import apiClient from '@/utils/api-client'
import {
    DeviceOs,
    DeviceType, Session,
} from "@/model/models";


class SessionService {
    disconnectChannelFromVidictId(vidictId: number) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/user/disconnect/' + vidictId).then((res) => {
                const result = res.data
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }

    updateSessionExtendedForUser(vidictId: number, deviceOs: DeviceOs, deviceType: DeviceType, appVersion: string){
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/user/session/update/' + vidictId + '/' + deviceOs + '/' + deviceType + '/'  + appVersion).then((res) => {//should the other variables also be added
                const result = res.data
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }

    updateAndDisconnectSession(session: Session) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.post('/api/v1/user/session/update-disconnect/', session ).then((res) => {
                if (res.status === 200 && res.data !== null) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default new SessionService()
