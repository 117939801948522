<template>
  <div class="row mx-2">
    <div class="col-md-6 offset-md-3"> <!-- Adjust the width and offset as needed -->
      <div class="card hovered" @click="navigateToEvents">
        <div class="mt-3 mx-3">
          <div class="row text-center" align="center">
            <div class="col-sm-12 col-12 d-flex justify-content-center">
              <h2 class="league-caption">{{ league_item.caption }}</h2>
            </div>
          </div>
        </div>
        <div class="row mx-2">
          <div class="col-sm-12 col-12">
            <div class="match-type" align="center">
              <span class="fw-bold">{{ league_item.description }}</span>
            </div>
          </div>
        </div>
        <div class="league-item">
          <img class="league-logo" :src="logoPath" :alt="league_item.logoAltText" style="max-width: 240px; max-height: 160px;">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "LeagueItem",
  props : ["league_item"],
  data() {
    return {
      userProfile: null,
      imageError: false
    }
  },
  computed: {
    logoPath() {
      try {
        return require('@/assets/' + this.league_item.logoFilename);
      } catch (error) {
        console.error('Failed to load image:', error);
        return '';
      }
    }
  },
  methods: {
    navigateToEvents() {
      localStorage.setItem('leagueId', this.league_item.leagueId)
      this.$router.push({ name: 'Events'});
      //this.$router.push('/events')
    },
    beforeMount() {
      if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
    }
  },
})
</script>

<style>
@import '../assets/style.css';
</style>
