import apiClient from '@/utils/api-client'

import {League} from "@/model/models";

class LeagueService {
    public getLeagueList() {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/leagues').then((res) => {
                const list: League[] = [];
                const leagueList = res.data.leagueList
                for (const x in leagueList){
                    if (leagueList[x].active) {
                        list.push(new League(leagueList[x].leagueId,leagueList[x].caption,leagueList[x].description,leagueList[x].logoFilename,
                            leagueList[x].logoAltText,leagueList[x].application,leagueList[x].status,leagueList[x].active, leagueList[x].events))
                    }
                }
                // for (const x in channelList) {
                //     if (channelList[x].active) {
                //         list.push(new Channel(channelList[x].channelId, channelList[x].channelName, channelList[x].appName, channelList[x].caption,
                //             channelList[x].eventFirstMatcher, channelList[x].eventSecondMatcher, channelList[x].eventLeague, channelList[x].edgeUrl,
                //             channelList[x].edgePort, channelList[x].pk, channelList[x].tags, channelList[x].orderNumber, channelList[x].vidictId,
                //             channelList[x].status, channelList[x].active));
                //     }
                // }
                // list.sort((n1, n2) => {
                //     if (n1.channelName > n2.channelName) {
                //         return 1;
                //     }
                //     if (n1.channelName < n2.channelName) {
                //         return -1;
                //     }
                //     return 0;
                // });
                //
                // list.sort((n1, n2) => {
                //     if (n1.orderNumber > n2.orderNumber) {
                //         return 1;
                //     }
                //     if (n1.orderNumber < n2.orderNumber) {
                //         return -1;
                //     }
                //     return 0;
                // });
                localStorage.setItem('leagueList', JSON.stringify(list))
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default new LeagueService()
