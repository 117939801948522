import axios, {AxiosInstance} from "axios";

class VidictService {

    public vidictDisconnect(edgeUrl: string, vidictId: string) {
        const apiVidictClient: AxiosInstance = axios.create({
            baseURL: edgeUrl,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        return new Promise<boolean>((resolve, reject) => {
            apiVidictClient.get('/disconnect/' + vidictId).then((res) => {
                resolve(true)
            }).catch((error) => {
                reject(error)
            })
        })
    }

}

export default new VidictService()
