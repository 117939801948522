<template>
  <!-- <Header></Header> -->
  <nav>
    <div class="navbar">
      <div class="container-fluid">
        <a class="navbar-brand" @click="goEvents">
          Events
        </a>
        <a class="navbar-brand" @click="goLeagues">
          Leagues
        </a>
        <div class="d-flex">
          <a class="mt-2 me-1" role="button" @click="doAccount">
            <svg class="account-svg bi bi-person-fill" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
            My Account
          </a>
        </div>
      </div>
    </div>
  </nav>

  <div class="main-height">
    <div v-if="error !== null && error !== ''">
      <div  class="d-flex flex-column beta-v px-3 align-items-center">
      <h5>{{ error }}</h5>
    </div>
  </div>
    <main class="live-match">
      <section class="match-top">
        <div class="container">
          <div class="row" align="center">
            <div class="col-12">
              <div v-if="mainEvent.channels[0].status && dynamicData.edgeAddress != null && dynamicData.edgeAddress !== ''" class="live">
                <p>LIVE</p>
              </div>
              <div v-else class="offline">
                <p class="mb-0">OFFLINE</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12" align="center">
              <div class="team-name">{{mainEvent.firstMatcher}}</div>
              <div class="vs">VS</div>
              <div class="team-name">{{mainEvent.secondMatcher}}</div>
            </div>
          </div>

          <div class="row">
            <div class=" col-12">
              <div align="center">
                <span class="fw-bold">{{mainEvent.league}}</span>
              </div>
            </div>
          </div>

          <!-- play button  -->
          <div class="row mx-2">
            <div class="col-12 mt-2" align="center">
              <button v-if="mainEvent.channels[0].status && dynamicData.edgeAddress != null && dynamicData.edgeAddress !== ''" class="btn text-white play-btn rounded-5" @click="toggleIsClicked">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-play-fill"
                  viewBox="2 2 16 16">
                  <path
                    d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                </svg>
                PLAY
              </button>
              <button v-else class="btn text-white play-btn rounded-5" @click="toggleIsClicked" disabled>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-play-fill"
                  viewBox="2 2 16 16">
                  <path
                    d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                </svg>
                PLAY
              </button>
            </div>
          </div>
        </div>
      </section>
      <hr class="seprate">
      <section class="other-games">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h4 class="fw-bold">Other Live Games</h4>
            </div>
          </div>

          <!--card slider  -->
          <div class="scrolling-wrapper mx-2 mt-2">

            <!-- card 1 start from here  -->
            <div class="card mx-2 mb-2" v-bind:key="event.eventId" v-for="event in this.eventsList">
              <EventItem v-bind:event_item="event"></EventItem>
            </div>
          </div>
        </div>
      </section>

    </main>
  </div>

  <Footer></Footer>

</template>

<script>
import {defineComponent} from 'vue'
// import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EventItem from '@/components/EventItem.vue'
import AuthService from "@/services/auth-service";
import UsersService from "@/services/users-service";
import ChannelService from "@/services/channel-service";
import VueSimpleAlert from "vue3-simple-alert";

export default defineComponent({
  name: "Events",
  components: {
    // Header,
    EventItem,
    Footer
  },
  data() {
    return {
      interval: null,
      eventsList: [],
      mainEvent: null,
      userPlan: null,
      userProfile: null,
      planEndDate: '',
      error: null,
      leaguesList: [],
      dynamicData: []
    }
  },
  methods: {
    doUpdateTrial: function () {
      this.$router.push('/plans')
    },
    goEvents () {
      this.$router.push('/events')
    },
    goLeagues () {
      this.$router.push('/leagues')
    },
    doAccount() {
      this.$router.push('/account')
    },
    getUserLatest: function () {
      UsersService.getUserLatest().then((newUserLatest) => {
        if (newUserLatest) {
          this.compareUserLatest(newUserLatest)
        }
      })
    },
    compareUserLatest: function (newUserLatest) {
      if (localStorage.getItem('userLatest') && localStorage.getItem('userLatest') !== JSON.stringify(newUserLatest)) {
        const oldUserLatest = JSON.parse(localStorage.getItem('userLatest'))
        // compare user latest
        if (oldUserLatest.loginId !== newUserLatest.loginId) {
          AuthService.signOut().then(() => {
            localStorage.setItem('sessionExpired', "true")
            this.$router.push('/signin')
          })
        }
        if (oldUserLatest.userProfile != newUserLatest.userProfile
            || oldUserLatest.userPlans != newUserLatest.userPlans) {
            // update user profile
          UsersService.getUserProfile().then(() => {
            if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
            if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
            if (this.userProfile.planActive == undefined && this.userProfile.planStatus == undefined) {
              // refunded user, show plans
              this.$router.push('/plans')
            }
            if (this.userPlan.trial && (this.userProfile.trialOverallTime <= 0 || this.userProfile.trialTodayTime <= 0)) {
              this.$router.push('/plans')
            }
            if (this.userProfile) this.planEndDate = new Date(this.userProfile.planEndDate).toLocaleDateString()
          })
        }
        if (oldUserLatest.payments != newUserLatest.payments) {
            // update user payments
        }
        if (oldUserLatest.channels != newUserLatest.channels) {
            // update user channels
          //Should events be in place of channels or should the whole part be removed
          ChannelService.getChannelList().then(() => {
            if (localStorage.getItem('channelList')) this.eventsList = JSON.parse(localStorage.getItem('channelList'))
            this.mainEvent = this.eventsList.shift()
          })
        }
      }

      this.error = newUserLatest.errorText
      localStorage.setItem('userLatest', JSON.stringify(newUserLatest))
    },
    toggleIsClicked: function () {
       if (!this.mainEvent.channels[0].status || this.dynamicData.edgeAddress === "" || this.dynamicData.edgeAddress === undefined) {
         VueSimpleAlert.alert("Channel is not available at the moment", "Channel error")
       } else {
        localStorage.setItem('channelName', this.mainEvent.channels[0].channelName)
        localStorage.setItem('eventTitle', this.mainEvent.firstMatcher + " VS " + this.mainEvent.secondMatcher)
        localStorage.setItem('eventTitle', this.mainEvent.league)
        if (this.mainEvent.channels[0].edgePort === "" || this.mainEvent.channels[0].edgePort === undefined) {
          localStorage.setItem('baseStreamUrl', "https://" + this.mainEvent.channels[0].edgeUrl + "/")
        } else {
          localStorage.setItem('baseStreamUrl', "https://" + this.dynamicData.edgeAddress+ ":" + this.mainEvent.channels[0].edgePort + "/")
        }
        localStorage.setItem('streamUrl', this.mainEvent.channels[0].appName + "/" + this.mainEvent.channels[0].hlsPrefix + this.mainEvent.channels[0].channelName
            + this.mainEvent.channels[0].hlsSufix + "/playlist.m3u8?id=" + this.mainEvent.channels[0].channelId + "&pk=" + this.dynamicData.pk + "&userId=" +
            this.userProfile.userId + "&vidictid=" + this.dynamicData.vidictId)
        localStorage.setItem('vidictId', this.dynamicData.vidictId)
        this.$router.push('/player')
      }
    },
    setData() {
        if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
        if (localStorage.getItem('leagueList')) this.leaguesList = JSON.parse(localStorage.getItem('leagueList'))

        if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
        if (this.userProfile && (this.userProfile.planEndDate < Date.now() || (this.userPlan.trial && (this.userProfile.trialOverallTime <= 0 || this.userProfile.trialTodayTime <= 0)))) {
        this.$router.push('/plans')
        }
        if (this.userProfile) this.planEndDate = new Date(this.userProfile.planEndDate).toLocaleDateString()
        if (localStorage.getItem('leagueList')) this.leaguesList = JSON.parse(localStorage.getItem('leagueList'))
        const leagueId = localStorage.getItem('leagueId');
        this.leaguesList.forEach(league => {
          // Check if the leagueId matches
          if (league.leagueId === parseInt(leagueId)) {
            // Add events to eventsList for the matched league
            league.events.forEach(event => {
              this.eventsList.push(event);
            });
          }
        });
        // Shift the first event if needed
        if (this.eventsList.length > 0) {
          this.mainEvent = this.eventsList.shift();
        }
        if (localStorage.getItem('dynamicData')) this.dynamicData = JSON.parse(localStorage.getItem('dynamicData'))
    },
    loadEvents() {

      // ChannelService.getChannelList().then(() => {
      //   if (localStorage.getItem('channelList')) this.eventsList = JSON.parse(localStorage.getItem('channelList'))
      //   this.mainEvent = this.eventsList.shift()
      // })
      // LeagueService.getLeagueList().then(() => {
      //   if (localStorage.getItem('leagueList')) this.leaguesList = JSON.parse(localStorage.getItem('leagueList'))
      // })
      UsersService.getDynamicData()
    }
  },
  beforeMount() {
    // prevent direct navigation bugs
    if (localStorage.getItem('userPlan') == null
        || localStorage.getItem('userProfile') == null
        || localStorage.getItem('leagueList') == null) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    } else {
      this.loadEvents()
    }
    this.setData()
  },
  mounted() {
    this.getUserLatest();
    this.interval = setInterval(function () {
      this.getUserLatest();
    }.bind(this), 30000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
})
</script>

<style>
@import '../assets/style.css';
</style>
