export class User {
    public email: string
    public firstName: string
    public lastName: string

    constructor(email: string, firstName: string, lastName: string) {
        this.email = email
        this.firstName = firstName
        this.lastName = lastName
    }
}

export class StatusResponse {
    public code: number
    public userId: string
    public message: string
    public timeStamp: number

    constructor(code: number, userId: string, message: string, timeStamp: number) {
        this.code = code
        this.userId = userId
        this.message = message
        this.timeStamp = timeStamp
    }
}

export class SignupRequest {
    public email: string
    public password: string
    public emailSubscribe: boolean
    public firstName: string
    public lastName: string

    constructor(email: string, password: string, emailSubscribe: boolean) {
        this.email = email
        this.password = password
        this.emailSubscribe = emailSubscribe
        this.firstName = 'Pro'
        this.lastName = 'Customer'
    }
}

export class Plan {
    public planId : string
    public planName : string
    public description : string
    public maxDevices : number
    public duration : string
    public orderNumber : number
    public price : number
    public active : boolean
    public status : boolean
    public trial : boolean
    public created : number
    public updated : number

    constructor(planId: string, planName: string, description: string, maxDevices: number, duration: string, orderNumber: number,
                    price: number, active: boolean, status: boolean, trial: boolean, created: number, updated: number) {
        this.planId = planId
        this.planName = planName
        this.description = description
        this.maxDevices = maxDevices
        this.duration = duration
        this.orderNumber = orderNumber
        this.price = price
        this.active = active
        this.status = status
        this.trial = trial
        this.created = created
        this.updated = updated
    }
}

export class UserProfileResponse {

    public userId: string
    public email: string
    public active : boolean
    public status : boolean
    public region : string
    public videoQuality: string
    public planId: string
    public planStartDate: number
    public planEndDate: number
    public planActive : boolean
    public planStatus : boolean
    public planCreated: number
    public planUpdated: number
    public trialOverallTime: number
    public trialTodayTime: number

    constructor(userId: string, email: string, active: boolean, status: boolean, region: string, videoQuality: string,
                planId: string, planStartDate: number, planEndDate: number, planActive: boolean, planStatus: boolean,
                planCreated: number, planUpdated: number, trialOverallTime: number, trialTodayTime: number) {
        this.userId = userId
        this.email = email
        this.active = active
        this.status = status
        this.region = region
        this.videoQuality = videoQuality
        this.planId = planId
        this.planStartDate = planStartDate
        this.planEndDate = planEndDate
        this.planActive = planActive
        this.planStatus = planStatus
        this.planCreated = planCreated
        this.planUpdated = planUpdated
        this.trialOverallTime = trialOverallTime
        this.trialTodayTime = trialTodayTime
    }
}

export class ContactFormRequest {
    public email: string
    public message: string
    public userInfo: string

    constructor(email: string, message: string, userInfo: string) {
        this.email = email
        this.message = message
        this.userInfo = userInfo
    }
}

export class PasswordRequest {
    public oldPassword: string
    public newPassword: string

    constructor(oldPassword: string, newPassword: string) {
        this.oldPassword = oldPassword
        this.newPassword = newPassword
    }
}
export class Event {
    public eventId: number;
    public eventName: string;
    public firstMatcher: string;
    public secondMatcher: string;
    public league: string;
    public leagueId: number;
    public application: string;
    public channels: Channel[];

    constructor(
        eventId: number,
        eventName: string,
        firstMatcher: string,
        secondMatcher: string,
        league: string,
        leagueId: number,
        application: string,
        channels: Channel[]
    ) {
        this.eventId = eventId;
        this.eventName = eventName;
        this.firstMatcher = firstMatcher;
        this.secondMatcher = secondMatcher;
        this.league = league;
        this.leagueId = leagueId;
        this.application = application;
        this.channels = channels;
    }
}

export class League {
    public leagueId : number
    public caption : string
    public description : string
    public logoFilename : string
    public logoAltText : string
    public application : string
    public status : string
    public active : boolean
    public events: Event[];

    constructor(leagueId: number, caption: string, description: string, logoFilename: string, logoAltText: string, application: string, status: string,
        active: boolean, events: Event[]) {
        this.leagueId = leagueId;
        this.caption = caption;
        this.description = description;
        this.logoFilename = logoFilename;
        this.logoAltText = logoAltText;
        this.application = application;
        this.status = status;
        this.active = active;
        this.events = events;
    }
}

export class Channel {
    public channelId : string
    public channelName : string
    public appName : string
    public caption : string
    public eventFirstMatcher : string
    public eventSecondMatcher : string
    public eventLeague : string
    public edgeUrl : string
    public edgePort : string
    public pk : string
    public tags : string
    public orderNumber : number
    public hlsPrefix : string
    public hlsSufix : string
    public vidictId: number//Should be deleted?
    public status : boolean
    public active : boolean

    constructor(channelId: string, channelName: string, appName: string, caption: string, eventFirstMatcher: string,
                eventSecondMatcher: string, eventLeague: string, edgeUrl: string, edgePort: string,
                pk: string, tags: string, orderNumber: number, hlsPrefix: string, hlsSufix: string, vidictId: number, status: boolean, active: boolean) {
        this.channelId = channelId
        this.channelName = channelName
        this.appName = appName
        this.caption = caption
        this.eventFirstMatcher = eventFirstMatcher
        this.eventSecondMatcher = eventSecondMatcher
        this.eventLeague = eventLeague
        this.edgeUrl = edgeUrl
        this.edgePort = edgePort
        this.pk = pk
        this.tags = tags
        this.orderNumber = orderNumber
        this.hlsPrefix = hlsPrefix
        this.hlsSufix = hlsSufix
        this.vidictId = vidictId
        this.status = status
        this.active = active
    }
}

export class UserLatestResponse {
    public userProfile: number
    public userPlans: number
    public payments: number
    public channels: number
    public loginId: string
    public errorText: string

    constructor(userProfile: number, userPlans: number, payments: number, channels: number, loginId: string, errorText: string) {
        this.userProfile = userProfile
        this.userPlans = userPlans
        this.payments = payments
        this.channels = channels
        this.loginId = loginId
        this.errorText = errorText
    }
}

export class Payment {
    public paymentId: string
    public plan: string
    public planEndDate: number
    public amount: number
    public paymentSystem: string
    public paymentDate: string

    constructor(paymentId: string, plan: string, planEndDate: number, amount: number, paymentSystem: string, paymentDate: number) {
        this.paymentId = paymentId
        this.plan = plan
        this.planEndDate = planEndDate
        this.amount = amount
        this.paymentSystem = paymentSystem
        this.paymentDate = new Date(paymentDate * 1000).toLocaleDateString()
    }
}

export class CouponResponse {
    public valid: boolean
    public couponCode: string
    public percentage: number

    constructor(valid: boolean, couponCode: string, percentage: number) {
        this.valid = valid
        this.couponCode = couponCode
        this.percentage = percentage
    }
}
export enum DeviceOs {
    ANDROID = "ANDROID",
    IOS = "IOS",
    WEB = "WEB",
    ANDROID_TV = "ANDROID_TV",
    LINUX = "LINUX",
    MACOS = "MACOS",
    WINDOWS = "WINDOWS",
    OTHER_OS = "OTHER_OS"
}

export enum DeviceType {
    CHROME = "CHROME",
    SAFARI = "SAFARI",
    EDGE = "EDGE",
    FIREFOX = "FIREFOX",
   SAMSUNG_INTERNET = "SAMSUNG_INTERNET",
    OPERA = "OPERA",
    APP = "APP",
    OTHER_BROWSER = "OTHER_BROWSER"
}
export class Device {
    public userId?: string;
    public deviceName: string;
    public deviceOs: DeviceOs;
    public deviceType: DeviceType;
    public appVersion?: null | string;
    public timestamp?: string;

    constructor(
        deviceName: string,
        deviceOs: DeviceOs,
        deviceType: DeviceType,
        userId?: string,
        appVersion?: string,
        timestamp?: string
    ) {
        this.userId = userId;
        this.deviceName = deviceName;
        this.deviceOs = deviceOs;
        this.deviceType = deviceType;
        this.appVersion = appVersion;
        this.timestamp = timestamp;
    }
}

export class Dynamic {
    public edgeAddress?: string;
    public pk: string;
    public vidictId?: number;

    constructor(
        edgeAddress: string,
        pk: string,
        vidictId: number,
    ) {
        this.edgeAddress = edgeAddress;
        this.pk = pk;
        this.vidictId = vidictId;
    }
}

export class Session {
    public vidictId?: number;
    public deviceOs?: DeviceOs;
    public deviceType?: DeviceType;
    public appVersion?: null | string;
    public userAgent?: string;

    constructor(
        vidictId?: number,
        deviceOs?: DeviceOs,
        deviceType?: DeviceType,
        appVersion?: string,
        userAgent?: string,
        ) {
        this.vidictId = vidictId;
        this.deviceOs = deviceOs;
        this.deviceType = deviceType;
        this.appVersion = appVersion;
        this.userAgent = userAgent;
    }
}

export class PasswordResetRequest {
    public email: string

    constructor(email: string) {
        this.email = email
    }
}

export class NewPasswordRequest {
    public email: string
    public token: string
    public newPassword: string

    constructor(email: string, token: string, newPassword: string) {
        this.email = email
        this.token = token
        this.newPassword = newPassword
    }
}