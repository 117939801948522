<template>

  <nav class="navbar">
    <div class="container-fluid d-flex justify-content-start">
      <a class="navbar-brand" @click="onLogoClicked">
        <img class="img-fluid" width="200px" src="../assets/logo.svg" alt="">
      </a>
    </div>
  </nav>

  <div class="desktop-account-page">
    <div class="main-height">
      <section class="account">
        <div class="container">
          <!-- ACCOUNT  -->
          <div class="main-heading">
            <h2 class="text-left">Account</h2>
          </div>
          <hr class="solid">
          <div class="row">
            <div class="col-3">
              <div class="sub-heading">
                <h5>MEMBERSHIP & BILLING</h5>
              </div>
            </div>
            <div class="col-6">
              <div class="membership-details">
                <p class="email">{{ this.userProfile.email }}</p>
                <!-- <p class="password">Password: <span>*******</span></p> -->
              </div>
            </div>
            <div class="col-3">
              <!-- change-password button  -->
              <div class="d-flex justify-content-end mb-1">
                <a class="account-button" role="button" @click="doChangePassword">Change Password</a>
              </div>
              <!-- Payment History button  -->
              <div class="d-flex justify-content-end mb-1">
                <a class="account-button" role="button" @click="doPaymentHistory">Payment History</a>
              </div>
              <!-- Conatct support button  -->
              <div class="d-flex justify-content-end mb-1">
                <a class="account-button" role="button" @click="doContactSupport">Contact Support</a>
              </div>
            </div>
          </div>

          <!-- VIDEO SETTINGS  -->
          <hr v-if="!this.userPlan.trial" class="solid">
          <div v-if="!this.userPlan.trial" class="row">
            <div class="col-3">
              <div class="sub-heading">
                <h5>VIDEO SETTINGS</h5>
              </div>
            </div>
            <div class="col-6">
              <div class="video-details">
                <p class="fw-bold d-inline">CDN Region: </p>
                <select v-model="selectedRegion" @change="handleRegionChange" class="dropdown-size-large" style="margin-left: 12px;">
                  <option v-for="(label, value) in regions" :key="value" :value="value">{{ label }}</option>
                </select>
                <div class="info-container">
                  <img class="info-img d-inline" src="../assets/info.svg" alt="">
                  <div class="info-text info-text-size-large">Buffering issues? Switch to a closer region. Otherwise, let our CDN auto-select for optimal performance.</div>
                </div>
              </div>
              <div class="video-details">
                <p class="fw-bold d-inline">Video Quality: </p>
                <select v-model="selectedVideoQuality" @change="handleVideoQualityChange" class="dropdown-size-large">
                  <option v-for="(label, value) in videoQualityList" :key="value" :value="value">{{ value }}</option>
                </select>
                <div class="info-container">
                  <img class="info-img d-inline" src="../assets/info.svg" alt="">
                  <div class="info-text info-text-size-large">Choose playback quality! Overrides auto for smoother streaming on varied connections.</div>
                </div>
              </div>
            </div>
          </div>

          <hr class="solid">
          <!-- PLAN DETAILS   -->

          <div v-if="this.userPlan != null">
            <div class="row">
              <div class="col-3">
                <div class="sub-heading">
                  <h5>PLAN DETAILS</h5>
                </div>
              </div>
              <div class="col-6">
                <div class="plan-details">
                  <div v-if="!this.userPlan.trial">
                    <div>
                      <div class="d-flex">
                        <p class="fw-bold">Plan: &nbsp;</p>
                        <p>Paid - {{this.userPlan.maxDevices}} {{ this.userPlan.maxDevices == 1 ? 'Screen' : 'Screens' }}</p>
                      </div>
                      <div class="d-flex">
                        <p class="fw-bold">Expiry: &nbsp;</p>
                        <p>{{this.planEndDate}}</p>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                      <div class="d-flex">
                        <p class="fw-bold">Plan: &nbsp;</p>
                        <p>Trial - {{this.userPlan.maxDevices}} {{ this.userPlan.maxDevices == 1 ? 'Screen' : 'Screens' }}</p>
                      </div>

                      <div class="d-flex">
                        <p class="fw-bold">Trial time left (today):&nbsp;</p>
                        <p>{{ this.userProfile.trialTodayTime > 0 ? (Math.round(this.userProfile.trialTodayTime/60)) : 0 }} Minutes</p>
                      </div>
                      <div class="d-flex">
                        <p class="fw-bold">Trial time left (lifetime):&nbsp;</p>
                        <p>{{ this.userProfile.trialOverallTime > 0 ? (Math.round(this.userProfile.trialOverallTime/60)) : 0 }} Minutes
                        </p>
                      </div>
                  </div>
                </div>
              </div>

              <div class="col-3">
                <div class="d-flex justify-content-end mb-1">
                  <!--
                  <div v-if="this.userPlan != null && !this.userPlan.trial">
                    <a role="button" class="account-button" @click="doUpgradePlan">Upgrade Plan</a>
                  </div>
                  -->
                  <div v-if="this.userPlan == null || this.userPlan.trial">
                    <a role="button" class="account-button" @click="doAddPackage">Add package</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SETTINGS  -->
          <hr class="solid">
          <div class="row">
            <div class="col-3">
              <div class="sub-heading">
                <h5 class="text-left">SETTINGS</h5>
              </div>
            </div>
            <div class="col-6">
              <!-- button  -->
              <div class="mb-1">
                <a class="account-button" role="button" @click="doSignOut">Sign out</a>
              </div>
              <!-- button  -->
              <div>
                <!-- Button trigger modal -->
                <a role="button" class="account-button" data-toggle="modal" data-target="#exampleModalCenter" @click="showModal = true">Sign out
                  from all devices
                </a>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>


  <div class="mobile-account-page">
    <section class="account-page">
      <!-- MY ACCOUNT  -->
      <div class="row mx-1">
        <div class="account-inner-container">
          <div class="col-sm-12 col-12 mt-2 text-justify">
            <!-- ACCOUNT  -->
            <div class="box-outline">
              <div class="mx-2 my-3">
                <h5 class="text-left account-heading">MEMBERSHIP & BILLING</h5>
                <hr class="solid">
                <p class="email">{{ this.userProfile.email }}</p>
                <hr class="solid">
                <!--change password button  -->
                <div>
                  <a class="account-button" role="button" @click="doChangePassword">Change Password</a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <!--upgrade plan button  -->
                <!--
                <hr class="solid">
                <div v-if="this.userPlan != null && !this.userPlan.trial">
                  <a role="button" class="account-button" @click="doUpgradePlan">Upgrade Plan</a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                    fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                -->
                <div v-if="this.userPlan == null || this.userPlan.trial">
                  <hr class="solid">
                  <a role="button" class="account-button" @click="doAddPackage">Add package</a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                    fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <hr class="solid">

                <!-- payment History button  -->
                <div>
                  <a class="account-button" role="button" @click="doPaymentHistory">Payment History</a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <hr class="solid">
                <!-- contact support button  -->
                <div>
                  <a role="button" class="account-button" @click="doContactSupport">Contact Support</a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
              </div>
            </div>
            <!-- VIDEO SETTINGS  -->
            <div v-if="!this.userPlan.trial" class="my-3 box-outline">
              <div class="mx-2 my-3">
                <h5 class="text-left account-heading">VIDEO SETTINGS</h5>
                <hr class="solid">
                <div class="video-details">
                  <p class="fw-bold d-inline">CDN Region: </p>
                  <select v-model="selectedRegion" @change="handleRegionChange" class="dropdown-size-small" style="margin-left: 12px;">
                    <option v-for="(label, value) in regions" :key="value" :value="value">{{ label }}</option>
                  </select>
                  <div class="info-container">
                    <img class="info-img d-inline" src="../assets/info.svg" alt="">
                    <div class="info-text info-text-size-small">Buffering issues? Switch to a closer region. Otherwise, let our CDN auto-select for optimal performance.</div>
                  </div>
                </div>
                <hr class="solid">
                <div class="video-details">
                  <p class="fw-bold d-inline">Video Quality: </p>
                  <select v-model="selectedVideoQuality" @change="handleVideoQualityChange" class="dropdown-size-small">
                    <option v-for="(label, value) in videoQualityList" :key="value" :value="value">{{ value }}</option>
                  </select>
                  <div class="info-container">
                    <img class="info-img d-inline" src="../assets/info.svg" alt="">
                    <div class="info-text info-text-size-small">Choose playback quality! Overrides auto for smoother streaming on varied connections.</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- PLAN DETAILS   -->
            <div v-if="this.userPlan != null">
              <div v-if="!this.userPlan.trial" class="my-3 box-outline">
                <div class="mx-2 my-3">
                  <h5 class="text-left account-heading">PLAN DETAILS</h5>
                  <hr class="solid">
                  <div class="d-flex">
                    <p class="fw-bold">Paid: &nbsp;</p>
                    <p>Paid - {{this.userPlan.maxDevices}} {{ this.userPlan.maxDevices == 1 ? 'Screen' : 'Screens' }}</p>
                  </div>
                  <hr class="solid mt-0">
                  <div class="d-flex">
                    <p class="fw-bold">Expiry: &nbsp;</p>
                    <p>{{this.planEndDate}}</p>
                  </div>
                </div>
              </div>
              <div v-else class="my-3 box-outline">
                <div class="mx-2 my-3">
                  <h5 class="text-left account-heading">PLAN DETAILS</h5>
                  <hr class="solid">
                  <div class="d-flex">
                    <p class="fw-bold">Paid: &nbsp;</p>
                    <p>Trial - {{this.userPlan.maxDevices}} {{ this.userPlan.maxDevices == 1 ? 'Screen' : 'Screens' }}</p>
                  </div>
                  <hr class="solid mt-0">
                  <div class="d-flex">
                    <p class="fw-bold">Trial time left (today):&nbsp;</p>
                    <p>{{ this.userProfile.trialTodayTime > 0 ? (Math.round(this.userProfile.trialTodayTime/60)) : 0 }} Minutes</p>
                  </div>
                  <hr class="solid mt-0">
                  <div class="d-flex">
                    <p class="fw-bold">Trial time left (lifetime): </p>
                    <p>{{ this.userProfile.trialOverallTime > 0 ? (Math.round(this.userProfile.trialOverallTime/60)) : 0 }} Minutes
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- SETTINGS  -->
            <div class="my-3 box-outline">
              <div class="mx-2 my-3">
                <h5 class="text-left account-heading">SETTINGS</h5>
                <hr class="solid">
                <!-- button  -->
                <div>
                  <a role="button" class="account-button" @click="doSignOut">Sign out</a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <hr class="solid">
                <!-- button  -->
                <div>
                  <!-- Button trigger modal -->
                  <a role="button" @click="showModal = true" class="account-button" data-toggle="modal" data-target="#exampleModalCenter">
                    Sign out from all devices
                  </a>
                  <svg class="account-arrow bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>


  <!-- Modal -->
  <div class="modal d-block" v-if="showModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title fw-bold">Sign out</h4>
          <button role="button" class="close close-modal" data-dismiss="modal" aria-label="Close" @click="showModal = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">Do you want to sign out from all the devices?
        </div>
        <div class="modal-footer">
          <a class="btn cancel-btn" role="button" data-dismiss="modal" @click="showModal = false">Cancel</a>
          <a class="btn signout-popup text-white" role="button" @click="doLogoutFromAll">Yes</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for regions -->
  <div class="modal d-block" v-if="showRegionModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title fw-bold">Success</h4>
        </div>
        <div class="modal-body">Your region has been successfully changed.</div>
        <div class="modal-footer">
          <a class="btn signout-popup text-white" role="button" data-dismiss="modal" @click="showRegionModal = false">OK</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal for video quality -->
  <div class="modal d-block" v-if="showVideoQualityModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title fw-bold">Success</h4>
        </div>
        <div class="modal-body">Your video quality has been successfully changed.</div>
        <div class="modal-footer">
          <a class="btn signout-popup text-white" role="button" data-dismiss="modal" @click="showVideoQualityModal = false">OK</a>
        </div>
      </div>
    </div>
  </div>

  <Footer></Footer>
</template>

<script>
import {defineComponent} from 'vue'
import Footer from "@/components/Footer.vue";
import VueSimpleAlert from 'vue3-simple-alert'
import UsersService from "@/services/users-service";
import AuthService from "@/services/auth-service";
import PaymentsService from "@/services/payments-service";
import RegionService from "@/services/region-service";
import VideoQualityService from "@/services/video-quality-service";

export default defineComponent({
  name: "Account",
  components: {
    Footer
  },
  data() {
    return {
      userPlan: null,
      userProfile: null,
      planEndDate: '',
      showModal: false,
      showRegionModal: false,
      showVideoQualityModal: false,
      regions: null,
      videoQualityList: null,
      selectedRegion: "null",
      selectedVideoQuality: "Auto"
    }
  },
  beforeMount() {
    // prevent direct navigation bugs
    if (localStorage.getItem('userPlan') == null || localStorage.getItem('userProfile') == null) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    }

    if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
    if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
    if (this.userProfile) this.planEndDate = new Date(this.userProfile.planEndDate).toLocaleDateString()
    if (localStorage.getItem('coupon')) {
      localStorage.setItem('coupon', null)
    }
    if (localStorage.getItem('regions')) this.regions = JSON.parse(localStorage.getItem('regions'))
    if (this.userProfile.region !== undefined) this.selectedRegion = this.userProfile.region
    if (localStorage.getItem('videoQualityList')) this.videoQualityList = JSON.parse(localStorage.getItem('videoQualityList'))
    if (this.userProfile.videoQuality !== undefined) this.selectedVideoQuality = this.userProfile.videoQuality
  },
  beforeCreate() {
    if (localStorage.getItem('password') == "true") {
      localStorage.setItem('password', "false")
      VueSimpleAlert.alert("Your password has been successfully changed.", "Password changed")
    }
  },
  methods: {
    doChangePassword: function () {
      this.$router.push('/change-password')
    },
    doUpgradePlan: function () {
      this.$router.push('/plans')
    },
    doPaymentHistory: function () {
      PaymentsService.getPaymentList().then(() => {
        this.$router.push('/payment-history')
      })
    },
    doLogoutFromAll: function () {
      UsersService.logoutFromAllDevices().then((loginId) => {
        if (loginId != '') {
          const oldUserLatest = JSON.parse(localStorage.getItem('userLatest'))
          oldUserLatest.loginId = loginId
          localStorage.setItem('userLatest', JSON.stringify(oldUserLatest))
          this.showModal = false
          VueSimpleAlert.alert("You've been successfully logged out of all devices (except this)", "Logged out")
        }
      })
    },
    doContactSupport: function () {
      window.open("https://procricket.help/contact/");
    },
    doAddPackage: function () {
      this.$router.push('/plans')
    },
    doSignOut () {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    },
    handleRegionChange() {
      if (this.selectedRegion === "null") {
        RegionService.updateUserRegionNull().then(() => {
          this.showRegionModal = true
        })
      } else {
        RegionService.updateUserRegion(this.selectedRegion).then(() => {
          this.showRegionModal = true
        })
      }
    },
    handleVideoQualityChange() {
      if (this.selectedVideoQuality === "Auto") {
        VideoQualityService.updateUserVideoQualityNull().then(() => {
          this.showVideoQualityModal = true
        })
      } else {
        VideoQualityService.updateUserVideoQuality(this.selectedVideoQuality).then(() => {
          this.showVideoQualityModal = true
        })
      }
    },
    onLogoClicked() {
      if (localStorage.getItem('leagueId')) {
        this.$router.push('/events')
      }
      else {
        this.$router.push('/leagues')
      }
    }
  }
})
</script>

<style>
@import '../assets/style.css';
</style>
