<template>
  <!-- <Header></Header> -->
  <nav>
    <div class="navbar">
      <div class="container-fluid">
        <a class="navbar-brand">
          <img class="img-fluid" width="200px" src="../assets/logo.svg" alt=""  @click="goEvents">
        </a>
        <div class="d-flex">
          <a class="mt-2 me-1" role="button" @click="doAccount">
            <svg class="account-svg bi bi-person-fill" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
            My Account
          </a>
        </div>
      </div>
    </div>
  </nav>
  <div class="main-height player-style">
    <div className="col-md-offset-3 col-sm-7 col-sm-offset-3" style='margin:0px auto;'>
      <div className="account-heading"><strong>Playing now:</strong> {{ eventTitle }}</div>
      <video-player
          id="VideoPlayer"
          :class="[
          'vjs-big-play-centered',
        ]"
          :style="{ backgroundColor: 'black'}"
          :src=configVideoJS.src
          :autoplay=configVideoJS.autoplay
          :volume=configVideoJS.volume
          :controls=configVideoJS.controls
          :muted=configVideoJS.muted
          :width=configVideoJS.width
          :height=configVideoJS.height
          :fluid=configVideoJS.fluid
          :loop=configVideoJS.loop
          :crossorigin=configVideoJS.crossorigin
          :disablePictureInPicture=configVideoJS.disablePictureInPicture
          :preferFullWindow=configVideoJS.preferFullWindow
          :playsinline=configVideoJS.playsinline
          :plugins=configVideoJS.plugins
          :html5=configVideoJS.html5
          :options=configVideoJS.options
          @ready="handlePlayerReady"
          @mounted="handleMounted"
          @unmounted="handleUnmounted"
          @error="handleError"
          @emptied="handleEmptied"
          @abort="handleAbort"
          @suspend="handleSuspend"
          @stalled="handleStalled"
      >
      </video-player>
    </div>
  </div>
  <div class=""><Footer></Footer></div>
</template>

<script >
import {defineComponent, shallowRef, shallowReactive} from 'vue'
// import Header from '@/components/Header.vue';
import Footer from "@/components/Footer.vue";
import VueSimpleAlert from 'vue3-simple-alert';
import VidictService from "@/services/vidict-service";
import ChannelService from "@/services/channel-service";
import UsersService from "@/services/users-service";
import AuthService from "@/services/auth-service";
import SessionService from "@/services/session-service"
import UserAgentParser from '@/utils/user-agent-parser'
import { VideoPlayer } from '@videojs-player/vue'
import 'videojs-contrib-quality-levels'
import videojsqualityselector from 'videojs-hls-quality-selector'
import 'video.js/dist/video-js.css'
import videojslandscapefullscreen from 'videojs-landscape-fullscreen'
import { event } from "vue-gtag";

export default defineComponent({
  name: 'Player',
  components: {
    // Header,
    VideoPlayer,
    Footer
  },
  setup() {
    const playerPage = () => {
      event('player', { method: 'Google' })
    }
    return {
      playerPage
    }
  },

  data() {
    return {
      userProfile: null,
      userPlan: null,
      interval: null,
      streamUrl: "",
      baseStreamUrl: "",
      channelName: "",
      vidictId: "",
      handleErrorOnce: false,
      player: null,
      eventTitle: "",
      configVideoJS: null,
      userAgent: "",
      deviceType: null,
      deviceOs: null,
      appVersion: "",
      videoStarted: true,
    }
  },
  methods: {
    channelDisconnects: function() {
      this.videoStarted = false;
      VueSimpleAlert.alert("Channel is not available. Try again later.", "Player error")
      this.$router.push('/events')
    },
    handleMounted: function (payload) {
      //console.log('----handleMounted', payload)
      this.player = payload.player
    },
    goEvents () {
      this.$router.push('/events')
    },
    handleUnmounted: function () {
      //console.log('---handleUnmounted')
    },
    handlePlayerReady: function (event) {
      //console.log('---handlePlayerReady', event)
      if (this.userProfile.videoQuality == undefined) {
        this.player.hlsQualitySelector = videojsqualityselector;
        this.player.hlsQualitySelector({
          displayCurrentQuality: true,
          placementIndex: 2
        })
      }
      this.player.landscapeFullscreen = videojslandscapefullscreen;
      this.player.landscapeFullscreen({
        fullscreen: {
          enterOnRotate: true,
          exitOnRotate: true,
          alwaysInLandscapeMode: true,
          iOS: false
        }
      })

      /**
      this.player?.ready(() => {
        const tech = this.player.tech();
        if (tech && tech.vhs && tech.vhs.xhr) {
          tech.vhs.xhr.beforeRequest = (options) => {
            if (this.vidictId) {
              return options;  // Exit early if vidictId is already set
            }
            //console.log('Making request for:', options.uri);
            // Extract vidictId from the options.uri
            const matches = options.uri.match(/vidictid=(\d+)/);
            if (matches && matches[1]) {
              this.vidictId = matches[1];
              this.updateSession();
            }
            return options;
          };
        }
      });
      **/

      // this.player?.tech().on('retryplaylist', () => {
      //   console.log('retryplaylist');
      // })
    },
    handleError: function (event) {
      //console.log('---handleError', event)
      this.channelDisconnects()
    },
    handleEmptied: function (event) {
      //console.log('---handleEmptied', event)
    },
    handleSuspend: function (event) {
      //console.log('---suspend', event)
    },
    handleAbort: function (event) {
      //console.log('---abort', event)
    },
    handleStalled: function (event) {
      //console.log('---stalled', event)
    },
    getUserLatest: function () {
      UsersService.getUserLatest().then((newUserLatest) => {
        if (newUserLatest) {
          this.compareUserLatest(newUserLatest)
        }
      })
    },
    compareUserLatest: function (newUserLatest) {
      if (localStorage.getItem('userLatest') && localStorage.getItem('userLatest') !== JSON.stringify(newUserLatest)) {
        const oldUserLatest = JSON.parse(localStorage.getItem('userLatest'))
        // compare user latest
        if (oldUserLatest.loginId !== newUserLatest.loginId) {
          AuthService.signOut().then(() => {
            localStorage.setItem('sessionExpired', "true")
            this.$router.push('/signin')
          })
        }
        if (oldUserLatest.userProfile != newUserLatest.userProfile
            || oldUserLatest.userPlans != newUserLatest.userPlans) {
          // update user profile
          UsersService.getUserProfile().then(() => {
            if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
            if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
            if (this.userPlan.trial && (this.userProfile.trialOverallTime <= 0 || this.userProfile.trialTodayTime <= 0)) {
              this.$router.push('/plans')
            }
            if (this.userProfile) this.planEndDate = new Date(this.userProfile.planEndDate).toLocaleDateString()
          })
        }
        if (oldUserLatest.payments != newUserLatest.payments) {
          // update user payments
        }
        if (oldUserLatest.channels != newUserLatest.channels) {//When UserLatest is updated, this should be to
          // update user channels
          ChannelService.getChannelList().then(() => {
            if (localStorage.getItem('channelList')) this.eventsList = JSON.parse(localStorage.getItem('channelList'))
          })
        }
      }

      localStorage.setItem('userLatest', JSON.stringify(newUserLatest))
    },
    doAccount() {
      this.$router.push('/account')
    },

    leavePage: function () {
      if (this.videoStarted === true) {
       this.player.dispose();
        clearInterval(this.interval);
        const session = UserAgentParser.createSession(this.vidictId, this.deviceOs, this.deviceType, this.appVersion, this.userAgent);
        SessionService.updateAndDisconnectSession(session);
      } else {
        clearInterval(this.interval);
      }
    },
  },
  mounted() {
    this.getUserLatest();
    this.interval = setInterval(function () {
      this.getUserLatest();
    }.bind(this), 30000);
  },
  beforeMount() {
      clearInterval(this.interval);
      if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
      if (localStorage.getItem('userPlan')) this.userPlan = JSON.parse(localStorage.getItem('userPlan'))
      if (localStorage.getItem('eventTitle')) this.eventTitle = localStorage.getItem('eventTitle')
      if (localStorage.getItem('baseStreamUrl')) this.baseStreamUrl = localStorage.getItem('baseStreamUrl')
      if (localStorage.getItem('baseStreamUrl') && localStorage.getItem('streamUrl')) this.streamUrl
          = localStorage.getItem('baseStreamUrl') + localStorage.getItem('streamUrl')
      if (localStorage.getItem('channelName')) this.channelName = localStorage.getItem('channelName')
      if (localStorage.getItem('vidictId')) this.vidictId = localStorage.getItem('vidictId')
      if (localStorage.getItem('coupon')) {
        localStorage.setItem('coupon', null)
      }
      this.configVideoJS = shallowReactive({
        src: this.streamUrl,
        autoplay: true,
        width: 800,
        height: 600,
        volume: 0.8,
        controls: true,
        fluid: true,
        muted: false,
        loop: false,
        crossorigin: 'anonymous',
        responsive: true,
        preferFullWindow: true,
        playsinline: false,
        disablePictureInPicture: true,
        plugins: {
        },
        html5: {
          vhs: {
            maxPlaylistRetries: 3,
            enableLowInitialPlaylist: true,
            limitRenditionByPlayerDimensions: false,
            overrideNative: true,
            useNetworkInformationApi: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        },
        options: {controlBar: { children: ['playToggle','liveDisplay', 'volumePanel', 'fullscreenToggle']} }
      })
      this.userAgent = navigator.userAgent;
      this.deviceType = UserAgentParser.extractDeviceType(this.userAgent);
      this.deviceOs = UserAgentParser.extractDeviceOs(this.userAgent);
      this.appVersion = UserAgentParser.getAppVersion(this.userAgent, this.deviceType);

  },

  beforeUnmount() {
    this.leavePage()
  },

  computed: {
  }
})
</script>

<style scoped>
@import '../assets/style.css';

.player-style {
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


</style>
